<template>
    <div class="edit-profile-detail">
        <StackRouterHeaderBar left-button="close" title="소득사항" :showTitle="true" />
        <div class="title" v-html="$translate(type.toUpperCase())" />
        <div class="section">
            <div class="column">
                <div
                    class="item"
                    :class="{ selected: item.id === selectedIdx }"
                    v-for="(item, idx) in profileOptions"
                    :key="item.id"
                    v-html="item.name"
                    @click="onClickItem(item, idx)"
                />
            </div>
        </div>
        <div class="section" v-if="showAddInfo">
            <div class="section-title required">소유자 명의</div>
            <div class="row">
                <div
                    class="item"
                    :class="{ selected: idx === selectedAddInfoIdx }"
                    v-for="(item, idx) in addInfo"
                    :key="item.name"
                    v-html="item.name"
                    @click="onClickAddItem(item, idx)"
                />
            </div>
            <!-- <div class="section-title required">정보공개 방법</div>
            <div class="selector-container">
                <div
                    class="select"
                    v-for="select in selectors"
                    :key="`select-${select.id}`"
                    @click="onClickSelector(select.id)"
                >
                    <i
                        class="material-icons m-r-4"
                        :class="{ 'c-primary': selectedShowMethod === select.id }"
                        v-html="`radio_button_${selectedShowMethod === select.id ? 'checked' : 'unchecked'}`"
                    />
                    <span v-html="select.key" />
                </div>
            </div> -->
        </div>
        <BottomButton
            :disabled="disabled"
            @click="submit"
            :label="$translate('SAVE')"
            v-if="type === 'house_payment'"
        />
    </div>
</template>

<script>
export default {
    name: 'EditProfileDetailHouse',
    props: {
        type: String,
    },
    data: () => ({
        selectedIdx: null,
        selectedAddInfoIdx: null,
        selectedOpen: null,
        showAddInfo: false,
        selectedShowMethod: null,
    }),
    computed: {
        profileOptions() {
            return this.$store.getters[`${this.$case.toCamel(this.type)}`]
        },
        profile() {
            return this.$store.getters.profile
        },
        addInfo() {
            return Object.keys(this.$profile.houseOwner).map(key => this.$profile.houseOwner[key])
        },
        selectors() {
            return [
                {
                    id: 1,
                    key: `전체 공개`,
                },
                {
                    id: 0,
                    key: `비공개`,
                },
            ]
        },
        disabled() {
            if (this.selectedIdx === 189) {
                return this.selectedAddInfoIdx === null
            }

            return this.selectedIdx === null
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.selectedIdx = this.profileOptions.find(item => item.name === this.profile[this.type]).id
            if (this.selectedIdx === 189) {
                this.showAddInfo = true
                this.selectedAddInfoIdx = this.$profile.houseOwner[this.profile.house_owner].id
                this.selectedShowMethod = (this.profile.privacy_setting || {}).show_house_owner || null
            }
        },
        submit() {
            if (this.type === 'house_style') return

            try {
                const item = this.profileOptions.find(option => option.id === this.selectedIdx)
                const privacy = this.profile.privacy_setting
                privacy.show_house_owner = this.selectedShowMethod

                this.$set(this.profile, this.type, item.name)
                this.$set(this.profile, 'house_owner', this.selectedAddInfoIdx !== null ? this.selectedAddInfoIdx : '')
                this.$profile.updateProfileOptions(this.type, item)
                this.$profile.setPrivacySettings(privacy)
            } catch (e) {
                console.log(e)
            }

            this.$stackRouter.pop()
        },
        onClickSelector(id) {
            this.selectedShowMethod = id
        },
        onClickItem(item, idx) {
            this.selectedIdx = item.id

            if (this.type === 'house_style') {
                setTimeout(() => {
                    this.$set(this.profile, this.type, item.name)
                    this.$profile.updateProfileOptions(this.type, item)

                    this.$stackRouter.pop()
                }, 200)
            } else {
                this.showAddInfo = idx === 0
                if (!this.showAddInfo) {
                    this.selectedAddInfoIdx = null
                    this.selectedShowMethod = null
                }
            }
        },
        onClickAddItem(item, idx) {
            this.selectedAddInfoIdx = idx
        },
    },
}
</script>

<style scoped lang="scss">
.row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
}
</style>
